<template>
  <div class="toggleContainer" :style="{ width: computedWidth, height: computedHeight }">
    <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ activeTab: activeTabIndex === index }"
        @click="toggleTab(index)"
        class="tab"
    >
      {{ tab.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabSwitcher',
  props: {
    tabs: Array,
    width: String,
    height: String,
    value: String
  },
  data() {
    return {
      defaultWidth: '260px',
      defaultHeight: '28px'
    };
  },
  computed: {
    computedWidth() {
      return this.width ? this.width : this.defaultWidth;
    },
    computedHeight() {
      return this.height ? this.height : this.defaultHeight;
    },
    activeTabIndex() {
      return this.tabs.findIndex(tab => tab.value === this.value);
    }
  },
  methods: {
    toggleTab(index) {
      this.$emit('input', this.tabs[index].value);
    },
  },
};
</script>

<style scoped lang="scss">
.toggleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 28px;
  border: 1px solid #D8DBE0;
  border-radius: 20px;
  background: #F3F3F3;
  font-weight: 600;
  color: #282f37;
  cursor: pointer;
  margin: 0 10px;
}
.toggleContainer .tab {
  flex: 1;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggleContainer .tab.activeTab {
  background: #007dfb;
  color: white;
  border-radius: 20px;
}
.toggleContainer .tab:not(:last-child) {
  //border-right: 1px solid #8bb3ff;
}
</style>
